
import { defineComponent } from "vue";
import { APPLICATION_DELETE_HMAC_AUTH } from "@/store-types";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";
import { emit } from "@/utils/event";
import { event, evenType } from "@/events/mixpanel/enums/applications";
import { mixpanelInstance } from "@/utils/mixpanel";

interface Data {
  isDeletingApplication: boolean;
}

export default defineComponent({
  data(): Data {
    return {
      isDeletingApplication: false,
    };
  },
  methods: {
    async deleteHmacAuthApplication() {
      if (this.isDeletingApplication) {
        return;
      }

      this.isDeletingApplication = true;

      const metadata = {
        CompanySSOID:
          this.$store.state.application.hmacAuthApplication?.companyId,
      };
      const dataEvent = {
        Remarks: "Success",
        Reason: "Success delete application",
      };

      try {
        await this.$store.dispatch(APPLICATION_DELETE_HMAC_AUTH, {
          uuid: this.$store.state.application.hmacAuthApplication?.id,
          pageName: "Applications",
        });
        mixpanelInstance.setMetadata(metadata);
        emit(event.DeleteApplication, dataEvent);
        this.handleSuccess(
          `${dataEvent.Reason} "${this.$store.state.application.hmacAuthApplication?.name}"`
        );
      } catch (err) {
        dataEvent.Remarks = "Failed";
        dataEvent.Reason = (err as any).response
          ? (err as any).response.data.message
          : "Something went wrong. Try again";
        mixpanelInstance.setMetadata(metadata);
        emit(event.DeleteApplication, dataEvent);
        this.handleError(err);
      } finally {
        this.$router.push("/dashboard/applications");
        this.isDeletingApplication = false;
      }
    },
    handleError(err: Error | any) {
      this.$store.dispatch(SHOW_TOAST, {
        message: err.message,
        type: ToastType.ERROR,
      });
    },
    handleSuccess(msg: string | any) {
      this.$store.dispatch(SHOW_TOAST, {
        message: msg,
        type: ToastType.SUCCESS,
      });
    },
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "warning" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t("pages.applications.advanced.form.delete")), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("pages.applications.advanced.form.warning")), 1),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-danger field-submit",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteHmacAuthApplication && _ctx.deleteHmacAuthApplication(...args)))
      }, _toDisplayString(_ctx.isDeletingApplication
            ? _ctx.$t("pages.delete_hmac_auth_application.form.button.deleting")
            : _ctx.$t("pages.delete_hmac_auth_application.form.button.delete")), 1)
    ])
  ]))
}